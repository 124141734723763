// React imports
import React, { useState } from 'react';

// Third-party imports
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// Croner imports
import { download } from '../../actions/files';

const Files = ({ file, progress, resource }) => {
  const [doc, setDoc] = useState('');

  const dispatch = useDispatch();
  const surveyId = sessionStorage.getItem('survey_id');

  const fileType = () => {
    let fileSplit = file.filename.split('.');
    let fileExt = fileSplit[fileSplit.length - 1];
    let fileType;

    if (fileExt === 'pdf') {
      fileType = <i className='far fa-file-pdf'></i>;
    } else if (fileExt === 'xlsx' || fileExt === 'xls') {
      fileType = <i className='far fa-file-excel'></i>;
    } else if (fileExt === 'doc' || fileExt === 'docx') {
      fileType = <i className='far fa-file-alt'></i>;
    } else if (fileExt === 'ppt' || fileExt === 'pptx') {
      fileType = <i className='far fa-file-powerpoint'></i>;
    } else if (fileExt === 'mp4') {
      fileType = <i className='fa fa-film'></i>;
    } else if (fileExt === 'txt') {
      fileType = <i className='far fa-file-alt'></i>;
    }

    return fileType;
  };

  const handleClick = (e) => {
    e.preventDefault();
    setDoc(e.target.name);
    dispatch(download(surveyId, e.target.name, resource));
  };

  return (
    <li key={file.guid}>
      <div>
        <Link
          name={file.guid}
          to={`/survey/${surveyId}/${resource}/${file.guid}/`}
          onClick={(e) => {
            handleClick(e);
          }}
        >
          {fileType()} {file.filename}
        </Link>
        {progress && progress.doc === doc ? (
          <span style={{ fontSize: '14px' }}>
            {' '}
            - {progress.current}/{progress.total} - {progress.percent}
          </span>
        ) : (
          <></>
        )}
      </div>
    </li>
  );
};

export default Files;

import {
  FILE_DOWNLOAD_PROGRESS,
  FILE_DOWNLOAD_STATUS
} from '../actions/files';

const initialState = {
  progress: [],
  status: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FILE_DOWNLOAD_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case FILE_DOWNLOAD_PROGRESS:
      const existingIndex = state.progress.findIndex(file => file.doc === action.payload.doc);
    
      let updatedProgress;
      if (existingIndex !== -1) {
        // Update the existing entry
        updatedProgress = state.progress.map((file, index) =>
          index === existingIndex ? { ...file, ...action.payload } : file
        );
      } else {
        // Add the new payload to the array
        updatedProgress = [...state.progress, action.payload];
      }
    
      return {
        ...state,
        progress: updatedProgress,
      };
    default:
      return state;
  }
}
import axios from 'axios';

// Croner Import
import {
  demoPosListData,
  demoQueryData,
} from '../components/Demo/data/demoData';

// Establish the different types
export const CHANGED = 'changed';
export const COUNTRY = 'country';
export const FILTER_RESULTS = 'filter_results';
export const PDF_PRINT = 'pdf_print';
export const POS_COUNT = 'pos_count';
export const POS_TITLE = 'pos_title';
export const QUERY = 'query';
export const RESULTS = 'results';
export const RESULTS_DOWNLOAD_ERROR = 'results_download_error';
export const RESULTS_DOWNLOAD_PROGRESS = 'results_download_progress';
export const RESULTS_DOWNLOAD_STATUS = 'results_download_status';
export const TERM = 'term';

export function basicQueryResults(cuts) {
  return function (dispatch) {
    dispatch({
      type: FILTER_RESULTS,
      payload: cuts,
    });
  };
}

export function clearCuts() {
  return function (dispatch) {
    dispatch({
      type: FILTER_RESULTS,
      payload: '',
    });
  };
}

// Clear out the store when going away from /resuls containers
export function clearResults() {
  return function (dispatch) {
    dispatch({
      type: FILTER_RESULTS,
      payload: '',
    });
    dispatch({
      type: QUERY,
      payload: '',
    });
    dispatch({
      type: RESULTS,
      payload: '',
    });
    dispatch({
      type: TERM,
      payload: '',
    });
    dispatch({
      type: RESULTS_DOWNLOAD_PROGRESS,
      payload: undefined,
    });
  };
}

export function clearQuery() {
  return function (dispatch) {
    dispatch({
      type: FILTER_RESULTS,
      payload: '',
    });
    dispatch({
      type: POS_TITLE,
      payload: '',
    });
    dispatch({
      type: QUERY,
      payload: '',
    });
  };
}

export function getQuery(posCode, id, code, country) {
  // console.log('functionState: ' + posCode);
  if (code.startsWith('E_')) {
    var strArray = posCode.split(' - ');
    var posCodeOnly = strArray[0];
    var posTitle = '';

    if (
      posCodeOnly < 100 ||
      (posCodeOnly >= 200000 && posCodeOnly <= 999999)
    ) {
      posTitle = strArray[1];
    } else if (
      (posCodeOnly >= 100 && posCodeOnly <= 9999) ||
      (posCodeOnly >= 10000 && posCodeOnly <= 99999) ||
      (posCodeOnly >= 100000 && posCodeOnly <= 199999) ||
      posCodeOnly >= 100000000
    ) {
      posTitle = strArray[1] + ' - ' + strArray[2];
    } else {
      posTitle = strArray[1] + ' - ' + strArray[2] + ' - ' + strArray[3];
    }

    // Remove this crap after 2025 results are up
    if (code === 'E_22' && strArray.length < 5) {
      posTitle = strArray[1] + ' - ' + strArray[2];
    } else if (code === 'E_22' && strArray.length === 5) {
      posTitle = strArray[1] + ' - ' + strArray[2] + ' - ' + strArray[3];
    }
  }
  // console.log('returnStart: ' + posTitle);
  return function (dispatch) {
    axios
      .get(
        `/api/client/survey/${id}/results/basic_query/${
          posCodeOnly ? posCodeOnly : posCode
        }/${
          (country &&
            code.startsWith('S_INTL') &&
            ((posCode > 300 && posCode < 4000) || posCode >= 100000)) ||
          (country &&
            code.startsWith('E_INTL') &&
            posCode > 200 &&
            posCode < 3000)
            ? '?country=' + country
            : ''
        }`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          },
        }
      )
      .then((response) => {
        dispatch({
          type: QUERY,
          payload: response.data,
        });
        // console.log('action posTitle: ' + posTitle);
        if (code.startsWith('E_')) {
          dispatch({
            type: POS_TITLE,
            payload: posTitle,
          });
        }
      });
  };
}

export function getResults(id) {
  return function (dispatch) {
    axios
      .get(`/api/client/survey/${id}/results/`, {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        dispatch({
          type: RESULTS,
          payload: response.data,
        });
        sessionStorage.setItem('survey_id', response.data.survey_guid);
      });
  };
}

export function pdfPrint(print) {
  return function (dispatch) {
    dispatch({
      type: PDF_PRINT,
      payload: print,
    });
  };
}

export function posCount(n) {
  return function (dispatch) {
    dispatch({
      type: POS_COUNT,
      payload: n,
    });
  };
}

export function searchTerm(term) {
  return function (dispatch) {
    dispatch({
      type: TERM,
      payload: term,
    });
  };
}

export function setCountry(country, changed) {
  return function (dispatch) {
    dispatch({
      type: COUNTRY,
      payload: country,
    });
    dispatch({
      type: CHANGED,
      payload: changed,
    });
  };
}

export function demoPosList() {
  return function (dispatch) {
    dispatch({
      type: RESULTS,
      payload: demoPosListData,
    });
  };
}

export function demoQuery(posCode) {
  return function (dispatch) {
    dispatch({
      type: QUERY,
      payload: demoQueryData.find((obj) => obj.pos_code === posCode),
    });
  };
}

// React imports
import React, { Component } from 'react';

// Third-party imports
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

//  Croner imports
import { clearDocuments } from '../../actions/documents';
import { clearMessages } from '../../actions/messages';
import Files from '../Files';

class SurveyResources extends Component {
  // clear out the related Redux store when navigating away
  componentWillUnmount() {
    this.props.clearDocuments();
  }

  // render the main element of the container
  render() {
    const { surveyResourcesDocs, progress } = this.props;

    return (
      <div id='survey_resources'>
        <Row>
          <Col lg='5'>
            <h4>Survey Resources</h4>
            <hr />
          </Col>
          <Col lg='7'>
            {surveyResourcesDocs.length > 0 ? (
              <ul>
                {surveyResourcesDocs.map((doc, index) => {
                  const docProgress = progress.find((p) => p.doc === doc.guid);
                  return (
                    <Files
                      key={index}
                      file={doc}
                      resource={'documents'}
                      progress={docProgress}
                    />
                  );
                })}
              </ul>
            ) : (
              <div>No documents available at this time.</div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

// map the state in the Redux store to props
function mapStateToProps(state) {
  return {
    surveyResourcesDocs: state.documents.survey_resources,
    progress: state.files.progress,
  };
}

export default connect(mapStateToProps, {
  clearDocuments,
  clearMessages,
})(SurveyResources);

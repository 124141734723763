// React imports
import React, { Component } from 'react';

// Third-party imports
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

//  Croner imports
import { clearDocuments } from '../../actions/documents';
import { clearMessages } from '../../actions/messages';
import Files from '../Files';

class Questionnaire extends Component {
  // clear out the related Redux store when navigating away
  componentWillUnmount() {
    this.props.clearDocuments();
  }

  // render the main element of the container
  render() {
    const { questionnaireDocs, progress } = this.props;

    return (
      <div id='questionnaire'>
        <Row>
          <Col lg='5'>
            <h4>Questionnaire</h4>
            <hr />
          </Col>
          <Col lg='7'>
            {questionnaireDocs.length > 0 ? (
              <ul>
                {questionnaireDocs.map((doc, index) => {
                  const docProgress = progress.find((p) => p.doc === doc.guid);
                  return (
                    <Files
                      key={index}
                      file={doc}
                      resource={'documents'}
                      progress={docProgress}
                    />
                  );
                })}
                {this.props.currentSurveyName === 'Croner CSF' &&
                questionnaireDocs.length ? (
                  <li>
                    <a
                      href='https://www.croner.com/csf-questionnaire-completion-webinar'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fa fa-link'></i> Croner CSF Questionnaire
                      Completion Webinars
                    </a>
                  </li>
                ) : questionnaireDocs.length ? (
                  <li>
                    <a
                      href='https://www.croner.com/croner-questionnaire-completion-webinar'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fa fa-link'></i> Croner Questionnaire
                      Completion Webinar
                    </a>
                  </li>
                ) : undefined}
              </ul>
            ) : (
              <div>No documents available at this time.</div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

// map the state in the Redux store to props
function mapStateToProps(state) {
  return {
    questionnaireDocs: state.documents.questionnaire,
    currentSurveyName: state.documents.currentSurveyName,
    progress: state.files.progress,
  };
}

export default connect(mapStateToProps, {
  clearDocuments,
  clearMessages,
})(Questionnaire);

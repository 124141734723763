// Third-party imports
import axios from 'axios';
import { saveAs } from 'file-saver';

// GLOBAL VARIABLES
export const FILE_DOWNLOAD_ERROR = 'file_download_error';
export const FILE_DOWNLOAD_PROGRESS = 'file_download_progress';
export const FILE_DOWNLOAD_STATUS = 'file_download_status';

export function download(id, doc, resource) {
  return function (dispatch) {
    dispatch({
      type: FILE_DOWNLOAD_STATUS,
      payload: true,
    });
    // send over the get request with the doc id
    // make sure the responseType is 'blob' to receive files
    axios
      .get(`/api/client/survey/${id}/${resource}/${doc}/`, {
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
        // Track download progress
        onDownloadProgress: (progressEvent) => {
          const total = progressEvent.total;
          const current = progressEvent.loaded;
          const percent = Math.round((current / total) * 100);

          // Dispatch progress to Redux store
          dispatch({
            type: FILE_DOWNLOAD_PROGRESS,
            payload: {
              doc: doc,
              percent: `${percent}%`,
              total: `${(total / (1024 * 1024)).toFixed(1)}MB`,
              current: `${(current / (1024 * 1024)).toFixed(1)}MB`,
            },
          });
        },
      })
      // get the filename from the response headers
      // and then download the file
      .then((response) => {
        let filename = '';
        let disposition = response.headers['content-disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }

        let blob = new Blob([response.data], { type: response.data.type });

        saveAs(blob, filename);

        dispatch({
          type: FILE_DOWNLOAD_STATUS,
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: FILE_DOWNLOAD_ERROR,
          payload: {
            error: [
              'There was an issue downloading the file.\n' +
                'The Croner Company has been notified and is investigating the issue.\n'
            ],
          },
        });
      });
  };
}
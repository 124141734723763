import {
  CHANGED,
  COUNTRY,
  RESULTS_DOWNLOAD_STATUS,
  FILTER_RESULTS,
  POS_COUNT,
  POS_TITLE,
  QUERY,
  RESULTS,
  TERM,
  PDF_PRINT,
} from '../actions/results';

export default function (state = {}, action) {
  switch (action.type) {
    case CHANGED:
      return {
        ...state,
        changed: action.payload,
      };
    case COUNTRY:
      return {
        ...state,
        country: action.payload,
      };
    case RESULTS_DOWNLOAD_STATUS:
      return {
        ...state,
        resultsDownloadStatus: action.payload,
      };
    case FILTER_RESULTS:
      return {
        ...state,
        cuts: action.payload,
      };
    case POS_COUNT:
      return {
        ...state,
        pos_count: action.payload,
      };
    case POS_TITLE:
      return {
        ...state,
        pos_title: action.payload,
      };
    case QUERY:
      return {
        ...state,
        query_data: action.payload.query_data,
        pos_code: action.payload.pos_code,
        pos_desc: action.payload.pos_desc,
      };
    case RESULTS:
      return {
        ...state,
        country_list: action.payload.country_list,
        data_effective: action.payload.effective_date,
        survey_list: action.payload.survey_list,
        survey_id: action.payload.survey_guid,
        survey_code: action.payload.survey_code,
        results: action.payload.results_docs,
        pos_list: action.payload.pos_list,
      };
    case TERM:
      return {
        ...state,
        search_term: action.payload,
      };
    case PDF_PRINT:
      return {
        ...state,
        pdf_print: action.payload,
      };
    default:
      return state;
  }

  // return state;
}

import _ from "lodash";
import React, { Component } from "react";
import { BlobProvider, Document } from "@react-pdf/renderer";
import { connect } from "react-redux";
import moment from "moment";
import { saveAs } from "file-saver";

import {
  Button,
  Col,
  Row,
  Popover,
  // PopoverHeader,
  PopoverBody,
} from "reactstrap";

import BasicQueryTemplate from "./Template";
import BasicQueryPercentiles from "./Percentiles";
import BasicQueryPrint from "./Print";

import { pdfPrint } from "../../../actions/results";



class BasicQueryResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      percentiles: {
        incN: 'n',
        incP1: 10,
        incP2: 25,
        incP3: 50,
        incMean: 'mean',
        incP4: 75,
        incP5: 90,
        coN: 'n',
        coP1: 50,
        coMean: 'mean',
      },
      percentilesAlt: {
        incN: 'n',
        incP2: 25,
        incP3: 50,
        incMean: 'mean',
        incP4: 75,
        coN: 'n',
        coP1: 25,
        coP2: 50,
        coMean: 'mean',
        coP3: 75,
      },
      percentilesCSF: {
        incN: 'n',
        incP1: 10,
        incP2: 25,
        incP3: 50,
        incMean: 'mean',
        incP4: 62.5,
        incP5: 75,
        incP6: 90,
        coN: 'n',
        coP1: 50,
        coMean: 'mean',
      },
      percentilesSWG: {
        incN: 'n',
        incP2: 25,
        incP3: 50,
        incMean: 'mean',
        incP4: 65,
        incP5: 75,
        coN: 'n',
        coP1: 25,
        coP2: 50,
        coMean: 'mean',
        coP3: 75,
      },
      percentilesPrgEnt: {
        incN: 'n',
        incP1: 10,
        incP2: 25,
        incP3: 50,
        incMean: 'mean',
        incP4: 65,
        incP5: 75,
        incP6: 90,
        coN: 'n',
        coP1: 50,
        coMean: 'mean',
      },
      disabled: false,
      popover: false,
      posDescHeight: 0,
    };
    this.handleNewPercentileVal = this.handleNewPercentileVal.bind(this);
    this.print = this.print.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getCurrency = this.getCurrency.bind(this);
  }

  getCurrency(country) {
    var currency = '';

    if (country === 'Canada') {
      currency = 'CAD ';
    } else if (country === 'China') {
      currency = 'CNY ';
    } else if (
      country === 'France' ||
      country === 'Germany' ||
      country === 'Spain' ||
      country === 'Finland' ||
      country === 'Ireland' ||
      country === 'Netherlands' ||
      country === 'Italy'
    ) {
      currency = 'EUR ';
    } else if (country === 'United Kingdom') {
      currency = 'GBP ';
    } else if (country === 'Australia') {
      currency = 'AUD ';
    } else if (country === 'India') {
      currency = 'INR ';
    } else if (country === 'Singapore') {
      currency = 'SGD ';
    } else if (country === 'South Korea') {
      currency = 'KRW ';
    } else if (country === 'Sweden') {
      currency = 'SEK ';
    } else if (country === 'Japan') {
      currency = 'JPY ';
    } else if (country === 'Poland') {
      currency = 'PLN ';
    } else if (country === 'Brazil') {
      currency = 'BRL ';
    } else if (country === 'Mexico') {
      currency = 'MXN ';
    } else {
      currency = '$USD ';
    }

    return '(in ' + currency + 'Thousands)';
  }

  // derive the position name based on the current position
  getPositionName(pos) {
    const { country, pos_list, pos_title } = this.props;

    let position_name = '';

    if (pos_list[0].country && country) {
      position_name = _.filter(pos_list, {
        finalcode: pos,
        country: country,
      });
    } else if (pos_list[0].foundationlabel && country) {
      position_name = _.filter(pos_list, {
        finalcode: pos,
        foundationlabel: country,
      });
    } else {
      position_name = _.filter(pos_list, { finalcode: pos });
    }

    // console.log(position_name);

    if (pos_title) {
      position_name = _.filter(pos_list, {
        finalcode: pos,
        postitle: pos_title,
      });
    }

    // console.log(pos_title);
    // console.log(position_name);

    if (pos_list[0].country && country) {
      return _.map(position_name, (pn) =>
        pn.country ? pn.postitle + ' - ' + pn.country : pn.postitle
      );
    } else if (pos_list[0].foundationlabel && country) {
      return _.map(position_name, (pn) =>
        pn.foundationlabel
          ? pn.postitle + ' - ' + pn.foundationlabel
          : pn.postitle
      );
    } else {
      return _.map(position_name, (pn) =>
        pn.country ? pn.postitle + ' - ' + pn.country : pn.postitle
      );
    }
  }

  // derive the survey name based on the current survey code
  getSurveyName(id) {
    const { country, survey_list } = this.props;

    let survey_name = _.filter(survey_list, { survey_id: id });
    return _.map(survey_name, (sn) =>
      country ? sn.survey + ' - ' + country : sn.survey
    );
  }

  // derive the survey name based on the current survey code
  getSurveyShortName(id) {
    const { survey_list } = this.props;
    // const { country, survey_list } = this.props;

    let survey_name = _.filter(survey_list, { guid: id });
    return _.map(survey_name, (sn) => sn.short_name);
  }

  handleDownload(filename, blob, url, e) {
    e.preventDefault();
    saveAs(blob, filename);
    this.props.pdfPrint(false);
    URL.revokeObjectURL(url);
  }

  handleNewPercentileVal(e) {
    const { query_data } = this.props;

    const {
      percentiles,
      percentilesAlt,
      percentilesCSF,
      percentilesSWG,
      percentilesPrgEnt,
    } = this.state;

    if (query_data[0].surveycode.startsWith('M_')) {
      this.setState({
        percentilesAlt: {
          ...percentilesAlt,
          [e.target.id]:
            e.target.value === 'mean'
              ? e.target.value
              : parseInt(e.target.value),
        },
      });
    } else if (query_data[0].surveycode.startsWith('F_')) {
      this.setState({
        percentilesCSF: {
          ...percentilesCSF,
          [e.target.id]:
            e.target.value === 'mean' || e.target.value === 62.5
              ? e.target.value
              : parseFloat(e.target.value),
        },
      });
    } else if (
      query_data[0].surveycode.startsWith('P_') ||
      query_data[0].surveycode.startsWith('E_')
    ) {
      this.setState({
        percentilesPrgEnt: {
          ...percentilesPrgEnt,
          [e.target.id]:
            e.target.value === 'mean'
              ? e.target.value
              : parseInt(e.target.value),
        },
      });
    } else if (query_data[0].surveycode.startsWith('S_')) {
      this.setState({
        percentilesSWG: {
          ...percentilesSWG,
          [e.target.id]:
            e.target.value === 'mean'
              ? e.target.value
              : parseInt(e.target.value),
        },
      });
    } else {
      this.setState({
        percentiles: {
          ...percentiles,
          [e.target.id]:
            e.target.value === 'mean'
              ? e.target.value
              : parseInt(e.target.value),
        },
      });
    }
    this.props.pdfPrint(false);
  }

  print() {
    this.props.pdfPrint(true);
    this.setState({
      disabled: true,
      posDescHeight: document.getElementById('position-desc').clientHeight,
    });
  }

  renderBasicQueryCuts(cuts, p, hourly, incCol, coCol, msoSales) {
    const { query_data } = this.props;
    // const { pos_title, query_data } = this.props;

    var i = 0;
    return _.map(_.pickBy(cuts, _.identity), (c) => {
      i++;
      let cut_results = _.filter(query_data, { sortorder: c });

      return _.map(cut_results, (cr) => {
        return (
          <BasicQueryTemplate
            key={cr.sortorder}
            cr={cr}
            p={p}
            hourly={hourly}
            i={i}
            incCol={incCol}
            coCol={coCol}
            effectiveDate={moment(this.props.data_effective)
              .format('LL')
              .toUpperCase()}
            maxPages={this.state.pages}
            surveyName={this.getSurveyName(this.props.survey_id)}
            totalCuts={Object.keys(_.pickBy(cuts, _.identity)).length}
            mso_sales={msoSales}
          />
        );
      });
    });
  }

  renderPercentiles(defaultPercentiles) {
    return (
      <tr>
        {_.map(defaultPercentiles, (p, key) => {
          return (
            <BasicQueryPercentiles
              key={key}
              v={p === 'mean' ? 'Mean' : p}
              k={key}
              handleClick={this.handleNewPercentileVal}
              csf={this.props.query_data[0].surveycode.startsWith('F_')}
            />
          );
        })}
      </tr>
    );
  }

  renderResultsBody(cuts, defaultPercentiles, hourly, incCol, coCol, msoSales) {
    // Determine the column widths of the top level headers
    return (
      <div id='bq-table'>
        <Row>
          <div className='table-responsive'>
            <table className='table table-condensed table-striped table-hover'>
              <thead className='table-heading'>
                <tr>
                  <th rowSpan='2' colSpan='3'>
                    TOTAL DIRECT COMPENSATION
                  </th>
                  <th colSpan={incCol}>INCUMBENT</th>
                  <th colSpan={coCol}>COMPANY</th>
                </tr>
                {this.renderPercentiles(defaultPercentiles)}
              </thead>
              {this.renderBasicQueryCuts(
                cuts,
                defaultPercentiles,
                hourly,
                incCol,
                coCol,
                msoSales
              )}
            </table>
          </div>
        </Row>
      </div>
    );
  }

  toggle() {
    this.setState({
      popover: !this.state.popover,
    });
  }

  render() {
    // Set prop values
    let {
      country,
      cuts,
      pdf_print,
      pos_code,
      pos_desc,
      query_data,
      survey_id,
    } = this.props;

    // Set state values
    const {
      percentiles,
      percentilesAlt,
      percentilesCSF,
      percentilesSWG,
      percentilesPrgEnt,
    } = this.state;

    let defaultPercentiles = {};

    // Figure out which default percentiles to use based on survey
    // Determine if hourly position (primarily MSO)
    if (query_data) {
      // console.log(query_data[0].surveycode);
      if (query_data[0].surveycode.startsWith('M_')) {
        defaultPercentiles = percentilesAlt;
      } else if (query_data[0].surveycode.startsWith('F_')) {
        defaultPercentiles = percentilesCSF;
      } else if (query_data[0].surveycode.startsWith('S_')) {
        defaultPercentiles = percentilesSWG;
      } else if (
        query_data[0].surveycode.startsWith('P_') ||
        query_data[0].surveycode.startsWith('E_')
      ) {
        //console.log('true');
        defaultPercentiles = percentilesPrgEnt;
      } else {
        defaultPercentiles = percentiles;
      }

      var hourly =
        query_data[0].surveycode.startsWith('M_') &&
        Number(query_data[0].finalcode.toString().slice(-2)) >= 31 &&
        Number(query_data[0].finalcode.toString().slice(-2)) <= 34 &&
        query_data[0].finalcode < 70000;
      var mso_sales =
        query_data[0].surveycode.startsWith('M_') &&
        query_data[0].finalcode >= 70000 &&
        !Number(query_data[0].finalcode.toString().slice(-2)) <= 11;
    }

    let incCol = _.map(
      _.pickBy(defaultPercentiles, function (value, key) {
        return _.startsWith(key, 'inc');
      })
    ).length;
    let coCol = _.map(
      _.pickBy(defaultPercentiles, function (value, key) {
        return _.startsWith(key, 'co');
      })
    ).length;

    // Get the survey code to use in the filename
    let time = new Date();
    let surveycode = query_data.find(o => o.country === country && o.hasOwnProperty('surveycode'))?.surveycode;
    if (!surveycode) {
      surveycode = query_data[0].surveycode
    }
    let filename =
      'The Croner Company - ' +
      surveycode +
      ' - Position ' +
      pos_code +
      ' - ' +
      (time.getHours() < 10 ? '0' + time.getHours() : time.getHours()) +
      time.getMinutes() +
      time.getSeconds() +
      '.pdf';

    return (
      <div className='bq-results'>
        <Row>
          <Col>
            {!pdf_print ? (
              <Button className='cuts-btn' color='success' onClick={this.print}>
                Print Downloadable PDF
              </Button>
            ) : (
              <BlobProvider
                document={
                  <Document>
                    <BasicQueryPrint
                      posCode={pos_code}
                      posName={this.getPositionName(pos_code)}
                      posDesc={pos_desc}
                      hourly={hourly}
                      incCol={incCol}
                      coCol={coCol}
                      percentiles={defaultPercentiles}
                      cuts={cuts}
                      queryData={query_data}
                      totalCuts={Object.keys(_.pickBy(cuts, _.identity)).length}
                      surveyName={this.getSurveyShortName(survey_id)}
                      dataEffective={moment(this.props.data_effective)
                        .format('LL')
                        .toUpperCase()}
                      msoSales={mso_sales}
                      country={country}
                      posDescHeight={this.state.posDescHeight}
                    />
                  </Document>
                }
              >
                {({ blob, url, loading, error }) => (
                  <>
                    <Button
                      id='download-button'
                      className='cuts-btn'
                      color='success'
                      onClick={(e) =>
                        this.handleDownload(filename, blob, url, e)
                      }
                      disabled={this.state.disabled && loading}
                    >
                      {loading
                        ? 'Loading document... This can take up to 15 seconds...'
                        : 'Document ready... Download Printable PDF'}
                    </Button>
                    <Popover
                      id='download-ready-popover'
                      placement='bottom'
                      isOpen={!loading}
                      toggle={this.toggle}
                      target='download-button'
                    >
                      {/* <PopoverHeader>Test</PopoverHeader> */}
                      <PopoverBody>
                        <p>
                          <i
                            onClick={(e) =>
                              this.handleDownload(filename, blob, url, e)
                            }
                            className='fas fa-download'
                          ></i>
                        </p>
                        <p>
                          The PDF has been generated and is ready for download!
                        </p>
                      </PopoverBody>
                    </Popover>
                  </>
                )}
              </BlobProvider>
            )}
          </Col>
        </Row>
        <br />

        <h4>{this.getSurveyName(survey_id)}</h4>
        <Row className='bq-header'>
          <h5 className='position-title'>
            Position {pos_code} - {this.getPositionName(pos_code)}
          </h5>
        </Row>
        <Row>
          <p id='position-desc'>{pos_desc}</p>
        </Row>

        <h5 className='section-heading'>
          TOTAL DIRECT COMPENSATION -{' '}
          {hourly ? '(in Hourly Rates)' : this.getCurrency(country)}
        </h5>

        {this.renderResultsBody(
          cuts,
          defaultPercentiles,
          hourly,
          incCol,
          coCol,
          mso_sales
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    country: state.results.country,
    cuts: state.results.cuts,
    data_effective: state.results.data_effective,
    pdf_print: state.results.pdf_print,
    pos_code: state.results.pos_code,
    pos_desc: state.results.pos_desc,
    pos_list: state.results.pos_list,
    pos_title: state.results.pos_title,
    survey_list: state.results.survey_list,
    survey_id: state.results.survey_id,
    query_data: state.results.query_data,
  };
}

export default connect(mapStateToProps, { pdfPrint })(BasicQueryResults);

// React imports
import React, { Component } from 'react';

// Third party imports
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

// Croner imports
import { clearMessages } from '../../actions/messages';
import { clearResults } from '../../actions/results';
import Files from '../Files';

class ResultsDocuments extends Component {
  // clear out the related Redux store when navigating away
  componentWillUnmount() {
    this.props.clearResults();
  }

  // render the main element of the container
  render() {
    const { progress, results } = this.props;

    return (
      <div id='results_documents'>
        <Row>
          <Col lg='5'>
            <h4>Results Documents</h4>
            <hr />
          </Col>
          <Col lg='7'>
            {results.length > 0 ? (
              <ul>
                {results.map((doc, index) => {
                  const docProgress = progress.find((p) => p.doc === doc.guid);
                  return (
                    <Files
                      key={index}
                      file={doc}
                      resource={'results'}
                      progress={docProgress}
                    />
                  );
                })}
              </ul>
            ) : (
              <div>No documents available at this time.</div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

// map the state in the Redux store to props
function mapStateToProps(state) {
  return {
    results: state.results.results,
    progress: state.files.progress,
  };
}

export default connect(mapStateToProps, {
  clearMessages,
  clearResults,
})(ResultsDocuments);
